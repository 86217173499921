.hero-wrapper{
    padding-top: 10px;
    display: flex;
    max-width: 100%;
    height: 100%;
    top: 0;
    position:relative;
    z-index: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 6vw ;
    padding-bottom: 300px;
    background: linear-gradient(181.3deg, #FFFFFF 4.28%, #F0F7F9 50.77%, #FFFFFF 98.89%);
         animation-name: fadeIn;     
         animation-duration: 2s;
}
.hero-image{
    opacity: 1;
    margin: -20;
    position: absolute;
    width: 90%;
    height:80%;
    left: 0px;
    top: 300px;
    z-index: 0;
}



.text-wrapper{
    display: flex;
    flex-direction: column;
    width: 90%;
    
    justify-content: center;
    line-height: 0px;
    z-index: 1;
    

}

.image-wrapper{
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.image1{
    margin: 20px;
    display: flex;
    resize: both;
    width:40vw;
    min-height:25vw;
    transition: 0.8s;
    cursor: pointer;
   
    background-image: url('../../../assets/imgs/windowClosedpng.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    
    
}

.image1:hover{
    margin: 20px;
    display: flex;
    resize: both;
    width:40vw;
    min-height:25vw;

    transition: 0.8s;
   
    background-image: url('../../../assets/imgs/beacWindow.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    
}

.image2{
    margin: 20px;
    display: flex;
    resize: both;
    width:40vw;
    min-height:25vw;
    transition: 0.8s;
    cursor: pointer;
   
    background-image: url('../../../assets/imgs/windowClosedpng.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    
}
.image2:hover{
    margin: 20px;
    display: flex;
    resize: both;
    width:40vw;
    min-height:25vw;
    transition: 0.8s;
   
    background-image: url('../../../assets/imgs/cityMountain.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    
}

.image3{
    margin: 20px;
    display: flex;
    resize: both;
    width:40vw;
    min-height:25vw;
    transition: 0.8s;
    cursor: pointer;
   
    background-image: url('../../../assets/imgs/windowClosedpng.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    
}

.image3:hover{
    margin: 20px;
    display: flex;
    resize: both;
    width:40vw;
    min-height:25vw;
    transition: 0.8s;
   
    background-image: url('../../../assets/imgs/cityWindow.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    
}

.image:hover{
    display: flex;
    resize: both;
    width:20vw;
    height:auto;
    opacity: 0;
    transition: 0.6s;
}


.h1-hero{
    font-family: 'Inter';
    color: #242424;
    font-size: 6vw;
    font-weight: 800;
}

.h1-outlined {

    font-family: 'Inter';
    color: #ffffff00;
    font-size: 6vw;
    font-weight: 800;

    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

}

@media only screen and (max-width: 740px){
    .hero-wrapper{
        display: flex;
        max-width: 97vw;
        margin: auto;
        height: 100%;
        overflow: auto;
        position:sticky;
        z-index: -1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0vw ;
        padding-top: 10vw;
        padding-bottom: 0vw;
        background: linear-gradient(181.3deg, #FFFFFF 4.28%, #F0F7F9 50.77%, #FFFFFF 98.89%);
    }
    .text-wrapper{
        display: flex;
        flex-direction: column;
        width: 90%;
        
        justify-content: center;
        line-height: 0px;
        z-index: 0;
        
    
    }
    .image-wrapper{
        

        background-color: #f1f1f100;
        overflow-x: scroll;
        justify-content:center;
        margin: 5px;
        padding: 20px;
      }
    

      .image1{
        margin: 20px;
        display: flex;
        resize: both;
        width:33vw;
        min-height:25vw;
        cursor: pointer;

        transition: 0.8s;
       
        background-image: url('../../../assets/imgs/beacWindow.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        
    }
    
    .image2{
        margin: 20px;
        display: flex;
        resize: both;
        width:33vw;
        min-height:25vw;
        cursor: pointer;
        
        transition: 0.8s;
       
        background-image: url('../../../assets/imgs/cityMountain.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        
    }
    .image3{
        margin: 20px;
        display: flex;
        resize: both;
        width:33vw;
        min-height:40vw;
        cursor: pointer;
    
        transition: 0.8s;
       
        background-image: url('../../../assets/imgs/cityWindow.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        
    }
    .h1-hero{
        font-family: 'Inter';
        color: #242424;
        font-size: 11vw;
        font-weight: 800;
    }
    
    .h1-outlined {
    
        font-family: 'Inter';
        color: #ffffff00;
        font-size: 11vw;
        font-weight: 800;
    
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
    
    }
    
    .hero-image{
        display:block;
        position: absolute;
        width: 400px;
        height: auto;
        left: 50px;
        top: 200px;
        z-index: 0;
    
    }
}
    