.aboutUs-wrapper {
    margin: auto;
    margin-top: 100px;
    height:auto;
    width: 98vw;
    background-color: #242424;
    padding-top:200px;
    padding-bottom:200px;
}

.h1-aboutUs{
    font-family: 'Inter';
    color: #ffffff;
    font-size: 6vw;
    font-weight: 800;
 
}
.h1-aboutUs-outlined{
    font-family: 'Inter';
    color: #242424;
    font-size: 6vw;
    font-weight: 800;
    margin-left: 1.5vw;



    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
}
.h2-aboutUs{
    font-family: 'Inter';
    color: #ffffff;
    font-size: 1.2vw;
    font-weight: 400;
}
.testimonial{
    font-family: 'Inter';
    color: #ffffff;
    font-size: 1vw;
    font-weight: 300;
    max-width: 300px;
    text-align: center;
}
.aboutUs-text-wrapper{
    display: flex;
    margin-left: 6vw;
    margin-top: 1vw;
    justify-content: flex-start;
    align-items: flex-start;
    width:60vw;
    z-index: 0;

}

.img-wrapper{
  
    display: flex;
    width: 80vw;
    margin: auto;
    margin-top: 8vw;
    margin-bottom: 8vw;
    align-items: center;
    justify-content:space-around;
  
}

.aboutus-img{
    
    width: 20vw;
    height: auto;
}
.social-wrapper{
  
    display: flex;
    width: 80vw;
    margin: auto;
    margin-top: 8vw;
    margin-bottom: 8vw;
    align-items: center;
    justify-content:space-evenly;
  
}
.social-img{
    width: 60px;
    height: auto;
    transition: 0.2s;
}
.social-img:hover{
    width: 70px;
    height: auto;
    transition: 0.2s;
}
.social-button{
    width: 100px;
    height: 100px;
    background-color: #24242400;
    border:none ;
}
.social-button:hover{
    cursor:pointer;
    width: 100px;
    height: 100px;
    background-color: #24242400;
    border:none ;
}

.testimonial-wrapper{
    display: flex;
    width: 80vw;
    margin: auto;
    margin-top: 10vw;
    margin-bottom: 8vw;
    align-items: center;
    justify-content:space-around;
}

@media only screen and (max-width: 740px){

    .aboutUs-wrapper {
        margin: auto;
        margin-top: 10vw;
        padding-top: 30px;
        margin-bottom: 0px;
        height:auto;
        width: 98vw;
        background-color: #242424;
        padding-bottom:2vw;
    }
    .h1-aboutUs{
        font-family: 'Inter';
        color: #ffffff;
        font-size: 11vw;
        font-weight: 800;
     
    }
    .h1-aboutUs-outlined{
        font-family: 'Inter';
        color: #242424;
        font-size: 11vw;
        font-weight: 800;
        margin-left: 1.5vw;
    
    
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgb(255, 255, 255);
    }
    .h2-aboutUs{
        font-family: 'Inter';
        color: #ffffff;
        font-size: 3.2vw;
        font-weight: 400;
    }

    .social-img{
      
        width: 30px;
        height: auto;
    }

    .social-img:hover{
      
        width: 30px;
        height: auto;
    }
    
    .social-img:active{
        width: 35px;
        height: auto;
    }
    .social-img:focus{
        width: 35px;
        height: auto;
    }

    .social-button{
        width: 50px;
        height: 50px;
        background-color: #24242400;
        border:none ;
    }

    .social-button:hover{
        width: 50px;
        height: 50px;
        background-color: #24242400;
        border:none ;
    }

    .social-button:active{
        width: 55px;
        height: 55px;
        background-color: #24242400;
        border:none ;
    }



    .testimonial{
        font-family: 'Inter';
        color: #ffffff;
        font-size: 2.6vw;
        font-weight: 300;
        max-width: 150px;
        text-align: center;
    }

    .testimonial-wrapper{
        display: flex;
        width: 90vw;
        margin: auto;
        margin-top: 10vw;
        margin-bottom: 8vw;
        align-items: center;
        justify-content:space-between;
    }
    .img-wrapper{
  
        display: flex;
        width:90vw;
        margin: auto;
        margin-top: 8vw;
        margin-bottom: 8vw;
        align-items: center;
        justify-content:space-around;
      
    }

    .aboutus-img{
        
        
        width: 25vw;
        height: auto;
}
}