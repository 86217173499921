
.aircraft-wrapper{
        
        margin: auto;
        padding-top: 200px;
        min-height: auto;
        height:auto;
        width: 98vw;
        background-color: #242424;
        padding-bottom: 6vw;
        background: #F0F7F9;
    
}
.aircrafts-text-wrapper{
    display: flex;
    margin-left: 6vw;
    margin-top: 0;
    justify-content: flex-start;
    align-items: flex-start;
    width:60vw;
    z-index: 0;

}
.h1-aircrafts{
    font-family: 'Inter';
    color: #242424;
    font-size: 6vw;
    font-weight: 800;
    margin-left: 1.5vw;
    
}
.h1-aircrafts-outlined{
    font-family: 'Inter';
    color: #ffffff00;
    font-size: 6vw;
    font-weight: 800;
    
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #242424;
}
.aircrafts-horizontal-nav{
    display: flex;
    height: auto;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50px;
    margin-left: 0;
    padding-left: 6vw;
    flex-direction: row;

    align-self: center;
    background: #242424;
    backdrop-filter: blur(30px);
  
  

}
.nav-button{

    margin-right: 6vw;
    background-color: #f0f7f900;
    border: none;
    width: 150px;
    height: 50px;
    align-content: center;
    justify-content: center;
    cursor: pointer;

    font-family: 'Inter';
    color: #FFFFFF;
    font-size: 1.5vw;
    font-weight: 400;
}

.nav-button-pressed{

    margin-right: 6vw;
    background-color: #f0f7f900;
    border: none;
    width: 150px;
    height: 50px;
    align-content: center;
    justify-content: center;
    cursor: pointer;

    font-family: 'Inter';
    color: #FFFFFF;
    font-size: 1.6vw;
    font-weight: 600;
    
}

.nav-button:hover{

    margin-right: 6vw;
    background-color: #f0f7f900;
    border: none;
    width: 150px;
    height: 50px;
    align-content: center;
    justify-content: center;
    cursor: pointer;

    font-family: 'Inter';
    color: #FFFFFF;
    font-size: 1.6vw;
    font-weight: 600;
    transition: 0.2s;
}
.tag{
    display: flex;
    flex-direction: row;
    height: auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 80px;

}
.rectangle-1 {
    align-self: center;
    margin-right: 20px;
    height: 1.5vw;
    width: 20px;
    background-color: #5c7da1;
  }

  .rectangle-2 {
    align-self: center;
    margin-right: 20px;
    height: 1.5vw;
    width: 20px;
    background-color: #34426c;
  }

  .rectangle-3 {
    align-self: center;
    margin-right: 20px;
    height: 1.5vw;
    width: 20px;
    background-color: #25304e;
  }
.label-tag{
    display: flex;
    margin: auto;
    content: "Entry-level";
    font-family: 'Inter';
    color: #242424;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    font-weight: 600;

}
.component-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 6vw;
    margin-top: 8vw;
    justify-content: flex-start;
    align-items: flex-start;
    width:60vw;
    z-index: 0;

}
.h2-aircrafts{

    font-family: 'Inter';
    color: #242424;
    font-size: 2vw;
    font-weight: 800;
}
.p-aircrafts{

    font-family: 'Inter';
    color: #7B7B7B;
    font-size: 1vw;
    font-weight: 300;
}

.aircrafts-img-wrapper{
  
    display:flex;
    width: 80vw;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    justify-content:flex-start;
    
  
}
.aircrafts-img{
    margin-right: 5vw;
    display: flex;
    justify-self: flex-start;
    width: 15vw;
    height: auto;
}
.micro-details{
    display: block;
    line-height: 10px;

}

.aircrafts-callToAction {
    width: 200px;
    height: 50px;
    margin-top: 1.4vw;

    background-color: #242424;
    color: #FFFFFF;

    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    border-style: none;
     
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 1.2vw;
    transition: 0.4s;
}

@media only screen and (max-width: 740px){
    .aircraft-wrapper{
        
        margin: auto;
        margin-top: 0px;
        min-height: auto;
        padding-top: 150px;
        height:auto;
        width: 98vw;
        background-color: #242424;
        padding-bottom: 6vw;
        background: #F0F7F9;
}
    .h1-aircrafts{
        font-family: 'Inter';
        color: #242424;
        font-size: 11vw;
        font-weight: 800;
        margin-left: 1.5vw;
        
    }
    .h1-aircrafts-outlined{
        font-family: 'Inter';
        color: #ffffff00;
        font-size: 11vw;
        font-weight: 800;
        
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #242424;
    }

    .aircrafts-callToAction {
        
        height: 50px;
    
        margin: auto;
        margin-top: 50px;
    
        background-color: #242424;
        color: #FFFFFF;
    
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        border-style: none;
         
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: small;
        transition: 0.4s;
    }
    .aircrafts-img-wrapper{
  
        display:flex;
        flex-direction: row;
        width: 80vw;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        justify-content:flex-start;
        overflow-x: scroll;
        justify-content:left;

      
    }
    .aircrafts-img{
        margin-bottom:20px ;
        margin-right: 5vw;
        display: flex;
        justify-self: flex-start;
        width: 60vw;
        height: auto;
    }

    .h2-aircrafts{

        font-family: 'Inter';
        color: #242424;
        font-size: 6vw;
        font-weight: 800;
        margin-bottom:40px ;
    }

    .p-aircrafts{
        line-height: 2;
        font-family: 'Inter';
        color: #7B7B7B;
        font-size: 3.5vw;
        font-weight: 300;
    }

    .label-tag{
        content: "Entry-level";
        font-family: 'Inter';
        color: #242424;
        font-size: 3vw;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        text-align: center;
        
    
    }

    
    

    .aircrafts-horizontal-nav{
        display: flex;
        height: auto;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 50px;
        margin-left: 0;
        padding-left: 6vw;
        flex-direction: row;
        justify-content:space-between ;
        align-self: center;
        background: #242424be;
        backdrop-filter: blur(30px);

    
    }

    .nav-button{

        margin-right: 6vw;
        background-color: #f0f7f900;
        border: none;
        width: 80px;
        height: 50px;
        align-content: center;
        justify-content: center;
        cursor: pointer;
    
        font-family: 'Inter';
        color: #FFFFFF;
        font-size: 2.5vw;
        font-weight: 400;
    }
    
    .nav-button-pressed{
    
        margin-right: 6vw;
        background-color: #f0f7f900;
        border: none;
        width: 80px;
        height: 50px;
        align-content: center;
        justify-content: center;
        cursor: pointer;
    
        font-family: 'Inter';
        color: #FFFFFF;
        font-size: 3vw;
        font-weight: 600;
        
    }
    
    .nav-button:hover{
    
        margin-right: 6vw;
        background-color: #f0f7f900;
        border: none;
        width: 150px;
        height: 50px;
        align-content: center;
        justify-content: center;
        cursor: pointer;
    
        font-family: 'Inter';
        color: #FFFFFF;
        font-size: 1.6vw;
        font-weight: 600;
        transition: 0.2s;
    }
    


}