.multi-button-container{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 60px;
}
.iterations{
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background-color: #9f9d9d1e;
    border-radius: 30px;
}

.iterations-two{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}