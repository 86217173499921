.bookNow-container{

        width: 100%;
        margin: auto;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
       padding-top: 100px;
        background-color: white;
  
    
}

.selector-container{
        display: flex;
        width: 70%;
        margin: auto;
        justify-content: space-around;
        text-align: center;
        margin-top: 10vw;
  
    
}

.bookNow-selector{
        color: #242424;
        margin: auto;
        font-size: small;
        font-family: 'Inter';
        font-weight: 600;
        width: 180px;
        height: 40px;
        border: solid 2px;
        border-radius: 5px;
        background-color: #0d1b2900;
        cursor: pointer;
        transition: ease-in-out 0.3s
    
    
    }

.bookNow-selector:hover{

        background-color: #242424;
        color: white;
    
    
}


.bookNow-selector-selected{
color: white;
margin: auto;
font-size: small;
font-family: 'Inter';
font-weight: 600;
width: 180px;
height: 40px;
border: solid 2px;
border-radius: 5px;
background-color: #242424;
cursor: pointer;
transition: ease-in-out 0.3s

}

.modal-bg {
        display: flex;
        margin: 0px;
        position:fixed;
        top: 0px;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(4, 5, 5, 0.384);
        backdrop-filter: blur(5px);
        z-index: 2;

        flex-direction: column;
        justify-content: center;
        align-items: center;
}

@media only screen and (max-width: 740px) {

        .selector-container{
                width: 100%;
        }


                

            
        

        
}