.navBar-wrapper {
    display: flex;
    width: 100%;
    margin: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    top: 0;
    position:sticky;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(30px);
    z-index: 3;

}

.links-wrapper {
    display: flex;
    width: 600px;
    justify-content: center;
    align-items: center;
}

.navBar-links {
    margin: auto;
    background-color: rgba(255, 255, 255, 0);
    color: #242424;
    cursor: pointer;
    border-radius: 0;
    border-style: none;
     
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: xx-small;
    transition: 0.2s;
}

.navBar-links:hover{
    margin: auto;
    background-color: rgba(255, 255, 255, 0);
    color: #242424;
    cursor: pointer;
    border-radius: 0;
    border-style: none;
     
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: xx-small;

    filter: drop-shadow(2px 2px 3px #5b5a5a2c);
    transition: 0.2s;
}

.navBar-logo {
    margin-left: 40px;
    width: 100px;
    height: auto;
}

.navBar-call-to-action {
    margin-left: 40px;
        margin-right: 40px;

        background-color: #242424;
        color: #FFFFFF;

        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        border-style: none;
         
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: xx-small;
        transition: 0.4s;
}

.navBar-call-to-action:hover {
    margin-right: 40px;

    background-color: #ffffff;
    color: #242424;

    padding: 10px 20px;;
    cursor: pointer;

    border-radius: 5px;
    border-style: none;
     
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: xx-small;
    filter: drop-shadow(2px 2px 3px #5b5a5a2c)
}

.navBar-hamburguer {
    display: none;
    margin-right: 20px;

    background-color: #242424;
    color: #FFFFFF;

    padding: 10px;
    min-width:40px;


    border-radius: 5px;
    border-style: none;
     
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: x-small;

    justify-content: center;
}

.mobile-menu-off {
    display: none;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(30, 25, 25, 0.384);
    backdrop-filter: blur(5px);
    z-index: 1;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 740px) {
  
    
    .mobile-menu {
        display: flex;
        position:fixed;
        top: 0px;
        width: 100vw;
        height: 100vh;
        background: rgba(9, 9, 9, 0.878);
        backdrop-filter: blur(5px);
        z-index: 3;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-menu-off {
        display: none;
        position: absolute;
        width: 100vw;
        height: 100vh;
        background: rgba(30, 25, 25, 0.384);
        backdrop-filter: blur(20px);
        z-index: 1;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .navBar-hamburguer{
        display: flex;
    }
    .navBar-call-to-action{
    display: none;
    }
    .links-wrapper{
        display: none;
    }

    .navBar-links{
        margin: 20px;
        color: #ffffff;
        font-size: small;
        font-weight: 400;
    }
    
    
}