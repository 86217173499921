.footer-wrapper {
    display: flex;
    height: 150px;
    width: auto;
  
  
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 50px ;
    background-color: #242424;
}

.footer-wrapper-contactus {
    display: flex;
    height: auto;
    width: auto;
    min-height: 50px;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding: 0px 50px ;
    background-color: #242424;
   
}


.footer-logo {
    margin-top: 20px;
    width: 150px;
    height: auto;
}
.footer-horizontal-wrapper {
    display: flex;
    background: #242424;

    height: 150px;
    width: auto;
    min-height: 50px;
    margin-top: 0px;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
    justify-content: space-between;

}

.p-footer {
    margin-top: 0px;
    font-family: 'Inter';
    font-size: 1vw;
    font-weight: 300;
    color: #b5b5b5;
    text-align: center;
    margin-right:100px;

}
.p-footerl {
    margin-top: 0px;
    font-family: 'Inter';
    font-size: 0.8vw;
    font-weight: 200;
    color: #b5b5b5;
    text-align: left;
    margin-right:100px;

}

.footer-font {

    font-size: large;
    color: #b5b5b5;
}

.footer-font2 {
    font-size: large;
    color: #b5b5b5;
}


.fsocial-wrapper {
    display: flex;
    background-color: #242424;
    border: solid;
    border-color: #ffe04300;
    border-radius: 50px;
    width: auto;
    height: 100px;
    z-index: 1;
    margin: 0px;
    margin-top: 0px;
    transition: .1s;
}

.fbutton-social {
    width: 50px;
    height: auto;
    background-color: #242424;
    border: none;
    margin: 20px;


}

.social-img-footer{
    width: 30px;
    height: auto;
    transition: 0.2s;
}

.social-img-footer:hover{
    cursor: pointer;
    width: 35px;
    height: auto;
    transition: 0.2s;
}

@media only screen and (max-width: 740px){
    .footer-horizontal-wrapper {
        display: flex;
        background: #242424;
    
        height: 200px;
        width: auto;
        min-height: 50px;
        margin-top: 0px;
        flex-direction: column;
        align-items: center;
        padding-left: 0px;
        justify-content: space-around;
    
    }

    .p-footer {

        margin-top: 0px;
        margin-right: 0px;
        font-family: 'Inter';
        font-size: 2vw;
        font-weight: 300;
        color: #b5b5b5;
        text-align: center;
    
    }
    .p-footerl {
        margin-top: 0px;
        font-family: 'Inter';
        font-size: 1.8vw;
        font-weight: 200;
        color: #b5b5b5;
        text-align: left;
    
    }

    .fsocial-wrapper {
        display: flex;
        background-color: #242424;
        border: solid;
        border-color: #ffe04300;
        border-radius: 50px;
        width: auto;
        height: 100px;
        z-index: 1;
        margin: 0px;
        margin-top: 0px;
        transition: .1s;
    }
    
    .fbutton-social {
        width: 50px;
        height: auto;
        background-color: #242424;
        border: none;
        margin: 20px;
    
    
    }

    .social-img-footer{
        width: 20px;
        height: auto;
    }

    .footer-logo {
        margin-top: 20px;
        width: 120px;
        height: auto;
    }
    

}