.form-wrapper{
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: center;
    align-items: center;
    margin-top: 80px;

}

.form-wrapper-2{
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  align-items: center;
  margin-top: 20;

}

.input-form{
    margin: 10px;
    width: 50%;
    background-color:#eeebeb;
    border: none;
    border-radius: 3px;
    padding: 10px;
    box-shadow:2px 2px 5px rgba(0, 0, 0, 0.06);

    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;

    color: #767676;

}

.input-form-passengers{
    margin: 10px;
    width: 10%;
    background-color:#eeebeb ;
    border: none;
    border-radius: 3px;
    padding: 10px;
    text-align: center;
    font-family: 'Inter';
    font-weight: 600;
    font-size: x-small;
    box-shadow:2px 2px 5px rgba(0, 0, 0, 0.06);

    color: #767676;;

}

.input-form-jet{
    margin: 10px;
    width: 100%;
    background-color:#eeebeb ;
    border: none;
    border-radius: 3px;
    padding: 10px;
    text-align: left;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    box-shadow:2px 2px 5px rgba(0, 0, 0, 0.06);
    color: #767676;;

}
.input-form-jet-2{
  margin: 10px;
  width: 40%;
  background-color:#eeebeb ;
  border: none;
  border-radius: 3px;
  padding: 10px;
  text-align: left;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  box-shadow:2px 2px 5px rgba(0, 0, 0, 0.06);

  color: #767676;

}


.input-form::placeholder{

    font-family: 'Inter';
    font-weight: 600;
    font-size: x-small;

    color: #3c3c3c48;

}

.submit{
    color: #ffffff;
    margin-top: 50px;
    font-size: small;
    font-family: 'Inter';
    font-weight: 700;
    width: 52%;
    height: 50px;
    border: solid 1px;
    border-radius: 5px;
    background-color: #242424;
    cursor: pointer;
    transition: ease-in-out 0.3s;
    margin-bottom:100px;
}

.input-and-buttons{
    display: flex;
    width: 80%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 20px;
}

.input-button{
    color: #242424;
    font-size: large;
    font-family: 'Inter';
    font-weight: 800;

    border: none;
    background-color: #0d1b2900;
    cursor: pointer;
    transition: ease-in-out 0.3s;

    

}

.input-button:hover{
    color: #000000;
    box-shadow:2px 2px 5px rgba(0, 0, 0, 0.1);
    transition: ease-in-out 0.3s;

}

.input-wrapper{
    width: 100%;
}




  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
    width: 38%;
    margin-right: 50px;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: #0d1b29d0;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.363);
    z-index: 1;
    backdrop-filter: blur(10px);
  }
  
  /* Links inside the dropdown */
  .dropdown-content button {
    background-color: #2b303600;
    border: none;
    color: #ffffff;
    padding: 12px 16px;
    font-family: 'Inter';
    font-size: x-small;
    text-decoration: none;
    display: flex;
    height: 40px;
   
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content button:hover {    
    color: #fff1d8;
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
    transition: ease-in-out 0.3s;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: flex;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }


  @media only screen and (max-width: 740px) {

    .input-and-buttons{
      display: flex;
      width: 100%;
      

    }

    .input-form{
      margin: 10px;
      width:90%;
      
      border: none;
      border-radius: 3px;
      padding: 10px;
  
      font-family: 'Inter';
      font-weight: 600;
    
  

      box-shadow:2px 2px 5px rgba(0, 0, 0, 0.06);

      color: #767676;
  
  }
  }
  